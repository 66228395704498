import * as _ from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { createSelector } from "reselect";

import { BOOK_FOR } from "../../constants/bookingSession";

import { PUBLIC_HOLIDAYS } from "../../helpers/constant";

const FMT = "YYYY-MM-DD";

const moment = extendMoment(Moment);
// momentHoliday.modifyHolidays.set('Germany/BW');

const isWeekend = (date) => date.isoWeekday() >= 6;

const isHoliday = (date, listHoliday) => {
  const dateFormated = moment(date).format("YYYY/MM/DD");
  const dateFormated2 = moment(date).format("YYYY-MM-DD");

  const dateHolidayChecked = (listHoliday || PUBLIC_HOLIDAYS).find((item) => item.date === dateFormated || item.date === dateFormated2);
  return dateHolidayChecked ? true : false;
};

const getReducer = ({ bookingSession }) => bookingSession.stage0;

export const isShowing = createSelector([getReducer], ({ isShowing }) => isShowing);

export const getBookingType = createSelector([getReducer], ({ bookingType }) => bookingType);

export const getParticipantNum = createSelector(
  [getReducer],
  ({ participantNumber }) => participantNumber,
);

export const getMinimumSelectableDate = createSelector(
  getReducer,
  ({ publicReducer }) => publicReducer.listHoliday,
  (data, listHoliday) => {
    const { leadTimeList, participantNumber } = data;
    const applicableList = _.filter(
      leadTimeList,
      (it) => _.get(it, "participants") >= participantNumber,
    );
    const allLeadTime = _.map(leadTimeList, (it) => _.get(it, "lead_time"));
    const maxLeadTime = _.max(allLeadTime);
    const minLeadTime = _.min(_.map(applicableList, (it) => _.get(it, "lead_time"))) || maxLeadTime;

    let okDate = moment(moment().format("YYYY-MM-DD"));
    let countDays = 0;
    while (countDays < minLeadTime) {
      okDate = okDate.add(1, "days");
      if (isWeekend(okDate) || isHoliday(okDate, listHoliday)) {
        console.log(`getMinimumSelectableDate() ===> ${okDate} is non working date`);
      } else {
        countDays += 1;
      }
    }

    return okDate;
  },
);

export const getDateSelectionType = createSelector([getReducer], ({ bookingType }) =>
  bookingType === BOOK_FOR.SINGLE_DAY ? "single" : "range",
);

export const getNumberOfCalendars = createSelector([getReducer], ({ bookingType }) =>
  bookingType === BOOK_FOR.SINGLE_DAY ? 1 : 2,
);

export const getDateRange = createSelector([getReducer], ({ bookingType, startDate, endDate }) => {
  if (startDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
    return null;
  }

  const dateRange = moment.range(
    moment(startDate.format("YYYY-MM-DD")),
    moment(endDate.format("YYYY-MM-DD")),
  );
  const result = bookingType === BOOK_FOR.SINGLE_DAY ? startDate : dateRange;
  return result;
});

export const getSelectableStartTimes = createSelector([getReducer], ({}) => {
  return _.map(_.range(8, 18), (it) => ({ label: `${_.padStart(it, 2, "0")}:00`, value: it }));
});

export const getStartTime = createSelector([getReducer], ({ startDate }) => {
  return startDate.hour();
});

export const getSelectableEndTimes = createSelector([getStartTime], (startTime) => {
  return _.map(_.range(9, 19), (it) => ({
    label: `${_.padStart(it, 2, "0")}:00`,
    value: it,
    disabled: it <= startTime,
  }));
});

export const getEndTime = createSelector([getReducer], ({ endDate }) => {
  return endDate.hour();
});

export const hasPresentationDevice = createSelector(
  [getReducer],
  ({ hasPresentationDevice }) => hasPresentationDevice,
);

export const getMaximumParticipants = createSelector([getReducer], ({ roomType }) =>
  _.get(roomType, "max_participants", 32),
);

export const hasWeekendOrHoliday = createSelector(
  getReducer,
  ({ publicReducer }) => publicReducer.listHoliday,
  (data, listHoliday) => {
    const { bookingType, startDate, endDate } = data;
    if (bookingType === BOOK_FOR.SINGLE_DAY) {
      return isWeekend(startDate) || isHoliday(startDate, listHoliday);
    }

    if (endDate.diff(startDate, "days") + 1 >= 6) return true;

    const dates = [];
    for (var d = startDate.clone(); d <= endDate; d = d.clone().add(1, "days")) {
      dates.push(d);
    }

    return _.some(_.map(dates, (d) => isWeekend(d) || isHoliday(d, listHoliday)));
  },
);

export const canSearch = createSelector(
  [
    getMaximumParticipants,
    getParticipantNum,
    getMinimumSelectableDate,
    getReducer,
    hasWeekendOrHoliday,
  ],
  (maxParticipants, participantNums, minDate, { startDate }, hasHld) => {
    let can = participantNums <= maxParticipants;
    can = can && startDate >= minDate;
    can = can && !hasHld;
    return can;
  },
);

export const isSearching = createSelector([getReducer], ({ isSearching }) => isSearching);

export const getLeadTimeList = createSelector([getReducer], ({ leadTimeList }) => leadTimeList);

export const isShowHolidayWarning = createSelector(
  [getReducer],
  ({ holidayWarning }) => holidayWarning.isShowing,
);

export const generateSearchPayload = createSelector(
  [getReducer, getStartTime, getEndTime],
  (
    { bookingType, roomType, participantNumber, startDate, endDate, hasPresentationDevice },
    startTime,
    endTime,
  ) => {
    let timeslots = [];
    if (bookingType === BOOK_FOR.SINGLE_DAY) {
      const slotTemplate = _.map(_.range(14), (it) => "0");
      for (let i = startTime; i < endTime; i++) {
        slotTemplate[i - 8] = "1";
      }
      const time = parseInt(slotTemplate.join(""), 2);
      timeslots.push({
        date: startDate.format(FMT),
        time,
      });
    } else {
      const days = endDate.diff(startDate, "days");
      let dateFrom = moment(startDate.format(FMT));
      for (let i = 0; i <= days; i++) {
        timeslots.push({
          date: dateFrom.format(FMT),
          time: 16368,
        });
        dateFrom = dateFrom.add(1, "days");
      }
    }

    const payload = {
      participants: participantNumber,
      permanent_facilities: hasPresentationDevice ? [1, 2] : [],
      timeslots,
    };

    if (roomType) {
      _.set(payload, "room_type", _.get(roomType, "id"));
    }
    return payload;
  },
);
